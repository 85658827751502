import { withBemMod, cn } from '@hmm/utils'
import { cnTheme } from '../Theme'
import styles from './Theme_control_default.module.css'

export interface ThemeControlDefaultProps {
  control?: 'default'
}

export const withControlDefault = withBemMod<ThemeControlDefaultProps>(
  cn(cnTheme, styles),
  {
    control: 'default'
  }
)
