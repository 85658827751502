import React, { FC, createContext } from 'react'
import { cn, IClassNameProps } from '@hmm/utils'

export interface ThemeProps extends IClassNameProps {
  children: React.ReactNode
}

export const cnTheme = cn('Theme')

export const ThemeContext = createContext({
  className: ''
})

/**
 * Компонент задает тему вложенным блокам
 *
 * ## Именование переменных
 * - Cостояние - не зависит от действий пользователя на странице {default,action,disabled,checked,warning,alert,info}
 * - Динамика - взаимодействие пользователя с контролом. {hover,press,focus,...}
 * - Категория - фон, шрифт, бордер, placeholder, тень, иконка
 *
 * @param className
 * @param children
 * @constructor
 */
export const Theme: FC<ThemeProps> = ({ className, children }) => {
  const _className = cnTheme(null, [className])

  return (
    <div className={_className}>
      <ThemeContext.Provider value={{ className: _className }}>{children}</ThemeContext.Provider>
    </div>
  )
}
