import {
  Preset,
  ClassNameList,
  NoStrictEntityMods,
  ClassNameFormatter as BaseFormatter
} from '@bem-react/classname'

export type ClassNameMap = Record<string, string>

/**
 * BEM Entity className initializer.
 */
export type ClassNameInitilizer = (
  blockName: string | ClassNameFormatter,
  elemName?: string | ClassNameMap,
  map?: ClassNameMap | number,
  options?: number
) => ClassNameFormatter

/**
 * BEM Entity className formatter.
 */
export interface ClassNameFormatter extends BaseFormatter {
  displayName: string
  map?: ClassNameMap
}

function mapCn(map: ClassNameMap, cn: string): string {
  return map[cn] || cn
}

function mapCnStrict(map: ClassNameMap, cn: string): string | undefined {
  // identity-obj-proxy may mock values
  return map[cn] !== cn && map[cn] || undefined
}

/** Режим доопределения. Дочерние стили видны родительским */
export const EXTEND_PARENT_MAP = 0b0
/** Режим переопределения. Дочерние стили не видны родительским  */
export const REDEFINE_PARENT_MAP = 0b1
/** Затирать родительские стили при совпадении */
export const OVERRIDE_PARENT_STYLES = 0b10

/**
 * BEM className configure function.
 *
 * @example
 * ``` ts
 *
 * import { withNaming } from '@bem-react/classname';
 *
 * const cn = withNaming({ n: 'ns-', e: '__', m: '_' });
 *
 * cn('block', 'elem'); // 'ns-block__elem'
 * ```
 *
 * @param preset settings for the naming convention
 */
export function withNaming(preset: Preset): ClassNameInitilizer {
  const nameSpace = preset.n || ''
  const modValueDelimiter = preset.v || preset.m

  function stringify(
    b: string,
    e?: string,
    m?: NoStrictEntityMods | null,
    mix?: ClassNameList,
    map: ClassNameMap = {}
  ) {
    const entityName = e ? nameSpace + b + preset.e + e : nameSpace + b
    let className: string = e
      ? mapCnStrict(map, e) || mapCn(map, entityName)
      : mapCn(map, entityName)

    if (m) {
      const modPrefix = entityName + preset.m

      for (const k in m) {
        if (m.hasOwnProperty(k)) {
          const modVal = m[k]

          if (modVal === true) {
            className += ' ' + mapCn(map, modPrefix + k)
          } else if (modVal) {
            className +=
              ' ' + mapCn(map, modPrefix + k + modValueDelimiter + modVal)
          }
        }
      }
    }

    if (mix !== undefined) {
      for (let i = 0, len = mix.length; i < len; i++) {
        const value = mix[i]

        // Skipping non-string values and empty strings
        if (!value || typeof value.valueOf() !== 'string') continue

        const mixes = value.valueOf().split(' ')

        for (let j = 0; j < mixes.length; j++) {
          const val = mixes[j]
          if (val !== entityName) {
            className += ' ' + val
          }
        }
      }
    }

    return className
  }

  return function cnGenerator(
    blockOrParentFormatter: string | ClassNameFormatter,
    elemOrMap?: string | ClassNameMap,
    mapOrOpts?: ClassNameMap | number,
    options = 0
  ): ClassNameFormatter {
    let parent: ClassNameFormatter | undefined, b: string, e: string | undefined
    let map: ClassNameMap | undefined

    if (typeof blockOrParentFormatter === 'string') {
      b = blockOrParentFormatter
    } else {
      parent = blockOrParentFormatter
      b = blockOrParentFormatter.displayName
    }
    if (typeof elemOrMap === 'string') {
      e = elemOrMap
      if (typeof mapOrOpts === 'number') {
        options = mapOrOpts
      } else {
        map = mapOrOpts
      }
    } else {
      map = elemOrMap
    }
    if (typeof mapOrOpts === 'number') {
      options = mapOrOpts
    }

    if (map) {
      map =
        options & REDEFINE_PARENT_MAP
          ? combineMaps(map, parent?.map, Boolean(options & OVERRIDE_PARENT_STYLES))
          : mergeMaps(map, parent?.map, Boolean(options & OVERRIDE_PARENT_STYLES))
    }

    const formatter: ClassNameFormatter = function (
      elemOrMods?: NoStrictEntityMods | string | null,
      elemModsOrBlockMix?: NoStrictEntityMods | ClassNameList | null,
      elemMix?: ClassNameList
    ) {
      if (typeof elemOrMods === 'string') {
        if (Array.isArray(elemModsOrBlockMix)) {
          return stringify(b, elemOrMods, undefined, elemModsOrBlockMix, map)
        }
        return stringify(b, elemOrMods, elemModsOrBlockMix, elemMix, map)
      }
      return stringify(b, e, elemOrMods, elemModsOrBlockMix as ClassNameList, map)
    }

    if (map) formatter.map = map

    if (typeof blockOrParentFormatter === 'string') {
      formatter.displayName = blockOrParentFormatter
    } else {
      Object.setPrototypeOf(formatter, blockOrParentFormatter)
    }

    return formatter
  }
}

export const cn = withNaming({
  e: '-',
  m: '_'
})

export function combineMaps(childMap: ClassNameMap, parentMap?: ClassNameMap, OVERRIDE_PARENT = false) {
  if (!parentMap) return childMap
  if (OVERRIDE_PARENT) return Object.setPrototypeOf(childMap, parentMap)

  for (const name of Object.keys(childMap)) {
    if (!parentMap[name]) continue
    childMap[name] = parentMap[name] + ' ' + childMap[name]
  }
  Object.setPrototypeOf(childMap, parentMap)
  return childMap
}

export function mergeMaps(childMap: ClassNameMap, parentMap?: ClassNameMap, OVERRIDE_PARENT = false): ClassNameMap {
  if (!parentMap) return childMap
  if (OVERRIDE_PARENT) return Object.assign(parentMap, childMap)

  for (const name of Object.keys(childMap)) {
    if (parentMap.hasOwnProperty(name)) {
      childMap[name] = parentMap[name] + ' ' + childMap[name]
    }
    parentMap[name] = childMap[name]
  }
  return parentMap
}
