import React from 'react'
import { ThemeProvider } from "@mui/material/styles";
import { compose, composeU } from '@hmm/utils'
import { hmm } from "./hmm";
import stylesTypography from './typography.module.css'
import stylesColor from './color.module.css'
import {
  Theme as ThemeBase,
  withTypoDefault,
  withSpaceDefault,
  withColorDefault,
  withControlDefault,
  withFontDefault,
  withColorDefaultFooter
} from '../Theme'
import clsx from "clsx";

const Theme = compose(
  withTypoDefault,
  withControlDefault,
  withFontDefault,
  withSpaceDefault,
  composeU(withColorDefault, withColorDefaultFooter)
)(ThemeBase)

/**
 * Тема витрины магазина
 * @param mode цветовая схема темы
 * @param children
 * @constructor
 */
export const ThemeHmm = ({
  mode = 'default',
  children
}: {
  mode?: 'dark' | 'light' | 'default'
  children: React.ReactNode
}) => (
  <Theme
    typo="default"
    space="default"
    color={mode === 'dark'? 'defaultFooter': 'default'}
    control="default"
    font="default"
    className={clsx(stylesTypography.Typography, stylesColor.Color)}
  >
    <ThemeProvider theme={hmm}>
      {children}
    </ThemeProvider>
  </Theme>
)
