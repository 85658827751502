import { withBemMod, cn } from '@hmm/utils'
import { cnTheme } from '../Theme'

import styles from './Theme_space_default.module.css'

export interface ThemeSpaceDefaultProps {
  space?: 'default'
}

export const withSpaceDefault = withBemMod<ThemeSpaceDefaultProps>(cn(cnTheme, styles), {
  space: 'default'
})
