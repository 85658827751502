import { createTheme, PaletteOptions, ThemeOptions } from '@mui/material/styles'
import { alpha } from '@mui/material'

export const paletteLight: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#0097d8',
    light: '#60c8ff',
    dark: '#0069a6'
  },
  secondary: {
    main: '#d84100',
    light: '#ff7436',
    dark: '#9f0000',
    contrastText: '#000'
  },
  error: {
    main: '#ff486a',
    light: 'rgba(255, 72, 106, 0.75)',
    dark: '#c30000',
    contrastText: '#fff'
  },
  warning: {
    main: '#ffda60',
    light: '#ffe17f',
    dark: '#b29843',
    contrastText: '#000'
  },
  success: {
    main: '#92C846',
    dark: '#668c31',
    light: '#a7d36b',
    contrastText: '#fff'
  },
  text: {
    primary: '#333',
    secondary: '#666',
    disabled: '#ddd'
  },
  divider: '#e1e1e1',
  background: {
    paper: '#fff',
    default: '#fff'
  },
}
export const themeOptions: ThemeOptions = {
  palette: paletteLight,
  spacing: 7,
  shape: {
    borderRadius: 6
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: 'var(--font-sans, sans-serif)',
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    h1: {
      fontWeight: 'var(--hmm--typography--h1-weight)',
      fontSize: 'var(--hmm--typography--h1-size)',
      lineHeight: 'var(--hmm--typography--h1-line)'
    },
    h2: {
      fontWeight: 'var(--hmm--typography--h2-weight)',
      fontSize: 'var(--hmm--typography--h2-size)',
      lineHeight: 'var(--hmm--typography--h2-line)'
    },
    h3: {
      fontWeight: 'var(--hmm--typography--h3-weight)',
      fontSize: 'var(--hmm--typography--h3-size)',
      lineHeight: 'var(--hmm--typography--h3-line)'
    },
    h4: {
      fontWeight: 'var(--hmm--typography--h4-weight)',
      fontSize: 'var(--hmm--typography--h4-size)',
      lineHeight: 'var(--hmm--typography--h4-line)'
    },
    body1: {
      fontWeight: 'var(--hmm--typography--body-weight)',
      fontSize: 'var(--hmm--typography--body-size)',
      lineHeight: 'var(--hmm--typography--body-line)'
    },
    body2: {
      fontSize: 'var(--hmm--typography--small-size)',
      lineHeight: 'var(--hmm--typography--small-line)',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: 16
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.gutterBottom && { marginBottom: 'var(--space-S)' })
        }),
        body1: ({ ownerState }) => ({
          ...(ownerState.gutterBottom && { marginBottom: 'var(--space-L)' })
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '13px 7px'
        },
        inputSizeSmall: {
          padding: '6px 7px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.startAdornment && { paddingLeft: 'var(--module)' }),
          ...(ownerState.endAdornment && { paddingRight: 'var(--module)' }),
          '&:not(.Mui-focused):not(.Mui-error):hover .MuiOutlinedInput-notchedOutline' : {
            borderColor: 'rgba(51, 51, 51, .54)',
          }
        }),
        multiline: {
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.position === 'start' && {
            marginRight: `var(--module)`
          }),
          ...(ownerState.position === 'end' && { marginLeft: `var(--module)` })
        })
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: `var(--module)`
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.body1.fontSize,
          lineHeight: theme.typography.body1.lineHeight
        }),
        sizeSmall: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight
        })
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          padding: '14px var(--space-M)',
          minWidth: 63,
          lineHeight: '21px',
          '&>.MuiSvgIcon-fontSizeMedium': {
            fontSize: 'var(--icon-size)'
          },
          '--icon-size': '21px'
        },
        outlinedPrimary: ({ theme }) => ({
          color: theme.palette.text.primary,
          borderColor: '#c4c4c4',
          '&:hover': {
            color: theme.palette.text.primary,
            borderColor: theme.palette.text.primary,
            backgroundColor: alpha(
              theme.palette.text.primary,
              theme.palette.action.hoverOpacity
            )
          }
        }),
        containedPrimary: ({ theme }) => ({
          color: theme.palette.text.primary,
          borderColor: '#c4c4c4',
          borderWidth: '1px',
          borderStyle: 'solid',
          backgroundColor: theme.palette.background.paper,
          '&:hover': {
            backgroundColor: theme.palette.background.paper
          }
        }),
        outlined: {
          paddingTop: 13,
          paddingBottom: 13
        },
        sizeLarge: {
          paddingTop: 17,
          paddingBottom: 17,
          fontSize: 16,
          lineHeight: '22px',
          fontWeight: 700
        },
        outlinedSizeLarge: {
          paddingTop: 16,
          paddingBottom: 16
        },
        sizeSmall: {
          fontSize: 'var(--size-typo-S)',
          lineHeight: '19px',
          paddingTop: 8,
          paddingBottom: 8
        },
        outlinedSizeSmall: {
          paddingTop: 7,
          paddingBottom: 7
        }
      }
    }
  }
}

export const hmm = createTheme(themeOptions)
