import React from 'react'
import { cn, IClassNameProps } from '@hmm/utils'
import styles from './Page.module.css'
import Head from 'next/head'
import { DS_PageMeta } from 'contracts/pages'
import Favicons from '../../../assets/favicons'

export interface PageProps extends IClassNameProps {
  children: React.ReactElement|React.ReactElement[]
  meta: DS_PageMeta
}

export const cnPage = cn('Page', styles)

export const Page: React.FC<PageProps> = ({ className, children, meta }) => {
  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <Favicons />
      </Head>
      <div className={cnPage(null, [className])}>{children}</div>
    </>
  )
}
