import { withBemMod, cn } from '@hmm/utils'
import { cnTheme } from '../Theme'

import styles from './Theme_typo_default.module.css'

export interface ThemeTypoDefaultProps {
  typo?: 'default'
}

export const withTypoDefault = withBemMod<ThemeTypoDefaultProps>(cn(cnTheme, styles), {
  typo: 'default'
})
