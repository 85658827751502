import { withBemMod, cn } from '@hmm/utils'
import { cnTheme } from '../Theme'
import styles from './Theme_color_default.module.css'

export interface ThemeColorDefaultProps {
  color?: 'default'
}

export const withColorDefault = withBemMod<ThemeColorDefaultProps>(cn(cnTheme, styles), {
  color: 'default'
})
