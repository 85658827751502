import React from "react"
export default () => {
  return (
    <>
      <link rel="icon shortcut" type="image/x-icon" href={require('./favicon.ico').src} />
      {[
        '57x57',
        '60x60',
        '72x72',
        '76x76',
        '114x114',
        '152x152',
        '180x180'
      ].map(size => (
        <link
          rel="apple-touch-icon"
          sizes={size}
          key={size}
          href={require(`./apple-icon-${size}.png`).default.src}
        />
      ))}
      {['192x192'].map(size => (
        <link
          rel="icon"
          type="icon/png"
          key={size}
          sizes={size}
          href={require(`./android-icon-${size}.png`).default.src}
        />
      ))}
      {['32x32', '96x96', '16x16'].map(size => (
        <link
          rel="icon"
          type="icon/png"
          key={size}
          sizes={size}
          href={require(`./favicon-${size}.png`).default.src}
        />
      ))}
    </>
  )
}
