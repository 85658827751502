import React from 'react'
import { Button as MuiButton, ButtonProps } from '@mui/material'
import { BemSize, sizeBemToMui } from '@hmm/utils'

export interface IButtonProps extends Omit<ButtonProps, 'size'> {
  size?: BemSize
}

export const Button = ({ size = 'm', ...props }: IButtonProps) => {
  return <MuiButton {...props} size={sizeBemToMui(size)} />
}
