import React, {MouseEventHandler} from 'react'
import { classnames } from '@bem-react/classnames'
import { IClassNameProps } from '@bem-react/core'

import logoDarkUrl from './logo__bg_view_dark.svg'
import logoLightUrl from './logo__bg_view_light.svg'
import logoSquareUrl from './logo_square.svg'
import logoOvalUrl from './Logo_oval.svg'
import styles from './Logo.module.css'

const views: Record<LogoViews, { src: string, width?: number, height?: number }> = {
  dark: typeof logoDarkUrl === 'object' ? logoDarkUrl : { src: logoDarkUrl },
  light: typeof logoLightUrl === 'object' ? logoLightUrl : { src: logoLightUrl },
  square: typeof logoSquareUrl === 'object'? logoSquareUrl : { src: logoSquareUrl },
  oval: typeof logoOvalUrl === 'object'? logoOvalUrl: { src: logoOvalUrl }
}

export enum LogoViews {
  light = 'light',
  dark = 'dark',
  square = 'square',
  oval = 'oval'
}

export interface ILogoProps extends IClassNameProps {
  view: LogoViews | 'light' | 'dark' | 'square' | 'oval'
  link?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

export const Logo: React.FC<ILogoProps> = ({
  className,
  view,
  link = false,
  onClick
}) => {
  const props = {
    role: 'banner',
    className: classnames(styles.Logo, className)
  }
  const img = React.createElement('img', {
    src: views[view].src,
    alt: 'HandmadeMart',
    width: views[view].width,
    height: views[view].height,
    className: styles.Image
  })
  return link
    ? React.createElement('a', { href: '/', onClick, ...props }, img)
    : React.createElement('span', props, img)
}
