enum BEM_TO_MUI {
  s = 'small',
  m = 'medium',
  l = 'large'
}
enum MUI_TO_BEM {
  small = 's',
  medium = 'm',
  large = 'l'
}
export type BemSize = 's' | 'm' | 'l'
export type MuiSize = 'small' | 'medium' | 'large'

export function sizeBemToMui(size: BemSize|undefined): MuiSize|undefined {
  return size && BEM_TO_MUI[size] || BEM_TO_MUI.m
}

export function sizeMuiToBem(size: MuiSize): BemSize {
  return MUI_TO_BEM[size] || MUI_TO_BEM.medium
}
