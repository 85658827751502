import { withBemMod, cn } from '@hmm/utils'
import { cnTheme } from '../Theme'
import styles from './Theme_font_default.module.css'

export interface ThemeFontDefaultProps {
  font?: 'default'
}

export const withFontDefault = withBemMod<ThemeFontDefaultProps>(cn(cnTheme, styles), {
  font: 'default'
})
