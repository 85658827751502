import { withBemMod, cn } from '@hmm/utils'
import { cnTheme } from '../Theme'

import styles from './Theme_color_defaultFooter.module.css'

export interface ThemeColorDefaultFooterProps {
  color?: 'defaultFooter'
}

export const withColorDefaultFooter = withBemMod<ThemeColorDefaultFooterProps>(
  cn(cnTheme, styles),
  { color: 'defaultFooter' }
)
